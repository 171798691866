import React from 'react'
import { Link } from 'react-router-dom';
import HomeBannerSlider from '../component/slider/HomeBannerSlider';

const HomeBanner = () => {
  return (
    <>
      <section className="main-slider-area slider-area-two">
        <div className="main-container">
          <div id="carousel-example-generic" className="carousel slide carousel-fade ">
            <ol className="carousel-indicators">
              <li data-target="#carousel-example-generic" data-slide-to={0} className="active" />
              <li data-target="#carousel-example-generic" data-slide-to={1} />
              <li data-target="#carousel-example-generic" data-slide-to={2} />
            </ol>
            <HomeBannerSlider />
            <div className="carousel-inner " role="listbox" >
              {/* <div className="item active slide-1">
                <div className="carousel-caption">
                  <h3 data-animation="animated fadeInUp"> Welcome To Our <span>Green Ville</span>.</h3>
                  <p data-animation="animated fadeInDown">Finding your ideal residential plot in Telangana is just a few steps away
                  </p>
                  <Link to="/aboutus" className="btn btn-default" data-animation="animated fadeInLeft">Read more</Link>
                </div>
              </div>
              <div className="item slide-2">
                <div className="carousel-caption">
                  <h3 data-animation="animated fadeInUp">
                    Make good Green Ville <span>property</span>.
                  </h3>
                  <p data-animation="animated fadeInDown">
                  Finding your ideal residential plot in Telangana is just a few steps away
                  </p>
                  <div className='abc3'><Link to="/aboutus" className="btn btn-default" data-animation="animated fadeInLeft">Read more</Link></div>  
                </div>
              </div>
              <div className="item slide-3 slide-text">
                <div className="carousel-caption">
                  <h3 data-animation="animated fadeInUp">
                    Clean water changes <span>everything</span> .
                  </h3>
                  <p data-animation="animated fadeInDown">
                  Finding your ideal residential plot in Telangana is just a few steps away 
                  </p>
                  <Link to="/aboutus" className="btn btn-default" data-animation="animated fadeInLeft">Read more</Link>
                </div>
              </div> */}
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default HomeBanner