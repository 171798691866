import React from 'react'
import { Link } from 'react-router-dom';

const ImageGallary = () => {
  return (
    <>
    
    
<div>
  <section className="banner-area over-layer-black">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <h2>Gallary Images</h2>
            <p><Link to="/">Home</Link> / Gallary Page</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="team-area bg-f8">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Gallary Images" src="assets/images/agent/1.png" />
                </Link>
                <div className="img-title">
                  <h3>Eric Gilbert</h3>
                  <p>Project Assistant</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>Eric Gilbert</h3>
                <p>Project Assistant</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Agent Images" src="assets/images/agent/2.png" />
                </Link>
                <div className="img-title">
                  <h3>Jackson Campbell</h3>
                  <p>Electricity Engineer</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>Jackson Campbell</h3>
                <p>Electricity Engineer</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Agent Images" src="assets/images/agent/3.png" />
                </Link>
                <div className="img-title">
                  <h3>Charles Creighton</h3>
                  <p>Civil engineer</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>Charles Creighton</h3>
                <p>Civil engineer</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Agent Images" src="assets/images/agent/4.png" />
                </Link>
                <div className="img-title">
                  <h3>Jordan Otis</h3>
                  <p>Mechanical Engineer</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>Jordan Otis</h3>
                <p>Mechanical Engineer</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Agent Images" src="assets/images/agent/5.png" />
                </Link>
                <div className="img-title">
                  <h3>Caleb Thornton</h3>
                  <p>Building Designer</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>Caleb Thornton</h3>
                <p>Building Designer</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Agent Images" src="assets/images/agent/6.png" />
                </Link>
                <div className="img-title">
                  <h3>John thornton</h3>
                  <p>Project Manager</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>John thornton</h3>
                <p>Project Manager</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Agent Images" src="assets/images/agent/7.png" />
                </Link>
                <div className="img-title">
                  <h3>William Fleming</h3>
                  <p>Project Assistant</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>William Fleming</h3>
                <p>Project Assistant</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="team-info item-4">
            <div className="single-agent">
              <div className="agent-img">
                <Link to="#">
                  <img alt="Agent Images" src="assets/images/agent/8.png" />
                </Link>
                <div className="img-title">
                  <h3>David Marlow</h3>
                  <p>Electricity Engineer</p>
                </div>
              </div>
              <div className="agent-details">
                <h3>Jackson Campbell</h3>
                <p>Electricity Engineer</p>
                <div className="last-about-details">
                  <ul>
                    <li><Link className="totop" to="#"><i className="fa fa-pinterest" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-facebook" /></Link></li>
                    <li><Link className="totop" to="#"><i className="fa fa-dribbble" /></Link></li>
                    <li><Link className="tobotm" to="#"><i className="fa fa-tumblr" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

    
    
    
    
    </>
  )
}

export default ImageGallary