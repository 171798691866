import React from 'react'
import TestimonalSlider from '../../component/slider/TestimonalSlider'

const Testimonal = () => {
  return (
    <> 

<section className="testimonal-area parallax-bg over-layer-black" style={{ background: "url(./images/GreenVilleBanner.jpg)" }}>
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2" >
        <div className="sec-title text-center mb-3 text-white" style={{color:"#00b050"}}>
          <h2 className="" style={{color:"#00b050"}}><b>Clients Feedback</b></h2>
        </div>
      </div>
    </div>
  
      <TestimonalSlider/>
        </div>






      







    
</section>





    </>
  )
}

export default Testimonal