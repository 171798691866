import React from 'react'
import { Link } from 'react-router-dom'

const Aboutuspage = () => {
    return (
        <>
            <section className="banner-area over-layer-black">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h2>About Us</h2>
                                <p><Link to="/"><a>Home</a></Link> / About Us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-area">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-12 col-md-5">
                            <br />
                            <br />
                            <br />
                            <img className="mb-4" src="assets/images/greenville5.webp" alt="About Image" />
                        </div>
                        <div className="col-sm-12 col-md-7">
                            <div className="about-content mt-4">
                                {/* <span>Take a look at some</span> */}
                                <h4><a >About Us </a></h4>
                                <p style={{ textAlign: "justify" }}>GreenVille projects Pvt ltd. Is a professionally managed as a corporate sector. The company led by Mr.Ram Singghh as C.M.D  is a dynamic leader with a sucessful track record over a period of 19 years in the real estate sector. He has a clear commitment towards the customers & dedication for their growth of company as well as the working partners & company employees.</p>
                                {/* <p>The company led by Mr.Ram Singghh as C.M.D  is a dynamic leader with a sucessful track record over a period pf 19 years in the real estate sector.</p>
                                <p>He has a clear commitment towards the customers & dedication for their growth of company as well as the working partners & company employees.</p> */}
                                <p style={{ textAlign: "justify" }}>GreenVille Projects Pvt Ltd has established a standing for supreme quality construction, achieving project excellence and delivering customer satisfaction.</p>
                                <p style={{ textAlign: "justify" }}>At GreenVille Projects Pvt. Ltd. we take pride in creating and delivering landmark projects.Our long-standing commitment to delivering innovative designs and craftmanship, in-depth knowledge, great experience in construction and extraordinary customer service has earned us the respect and trust of our customers year after year.
                                    We provide the highest levels of quality, integrity and safety to our customers.</p>

                                {/* <button type="button" className="mt-3 btn btn-theme text-uppercase">Read More</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="about-item style-1">
                                <div className="icon-box">
                                    <i className="flaticon-key-2" />
                                </div>
                                <h6><a >Our mission :</a></h6>
                                <p>Our mission is to be the number one brand in Real estate industry across , we mainly focus on quality development with clear title & resale assurances.</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="about-item style-1">
                                <div className="icon-box">
                                    <i className="flaticon-coins" />
                                </div>
                                <h6><a >Our vision :</a></h6>
                                <p>To be the most trusted brand in Real Estate sector by achieving 100% customer satisfaction with present running project and future upcoming projects.</p>
                            </div>
                        </div>
                        {/* <div className="col-sm-4">
                            <div className="about-item style-1">
                                <div className="icon-box">
                                    <i className="flaticon-home-2" />
                                </div>
                                <h6><a >Property News</a></h6>
                                <p>After selling property on a global scale, founder and property expert, Natasha McElwaine
                                    created McElwaine Estate Agents.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <section className="funfact-area parallax-bg over-layer-black" data-background="images/bg/2.jpg" data-type="parallax" data-speed={3}>
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <div className="counter-col text-center">
                                <div className="count">
                                    <i className="flaticon-people-4" />
                                    <div className="start-count">12345</div>
                                </div>
                                <p>Agents</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <div className="counter-col text-center">
                                <div className="count">
                                    <i className="flaticon-house-3" />
                                    <div className="start-count">23456</div>
                                </div>
                                <p>Property</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <div className="counter-col text-center">
                                <div className="count">
                                    <i className="flaticon-map-2" />
                                    <div className="start-count">34567</div>
                                </div>
                                <p>Project</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <div className="counter-col text-center">
                                <div className="count">
                                    <i className="flaticon-house-2" />
                                    <div className="start-count border-none">45678</div>
                                </div>
                                <p>Houses</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section id="features" className="features">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="image col-lg-6" data-aos="fade-right">
                            <img src="assets/images/greenville26.webp" alt />
                        </div>
                        <div className="col-lg-6" data-aos="fade-left" data-aos-delay={100}>
                            <h3>How to Get Started</h3>
                            <p>Finding your ideal residential plot in Telangana is just a few steps away:</p>
                            <div className="icon-box mt-2 mt-lg-0" data-aos="zoom-in" data-aos-delay={150}>
                                <i className="bx bx-receipt" />
                                <h4>Browse </h4>
                                <p>Explore our website to view the wide range of residential plots available. Filter your search based on location, size, and budget to find the perfect plot that matches your requirements.</p>
                            </div>
                            <div className="icon-box mt-2" data-aos="zoom-in" data-aos-delay={150}>
                                <i className="bx bx-cube-alt" />
                                <h4>Inquire </h4>
                                <p>If you find a plot that catches your interest, submit an inquiry through our website or contact our dedicated customer support team. We will be happy to provide you with detailed information and schedule a visit if needed.</p>
                            </div>
                            <div className="icon-box mt-2" data-aos="zoom-in" data-aos-delay={150}>
                                <i className="bx bx-images" />
                                <h4>Visit</h4>
                                <p>Arrange a visit to the chosen plot and experience the location first-hand. Our team will be there to accompany you and answer any questions you may have.</p>
                            </div>

                        </div>
                    </div>
                    <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                        <i className="bx bx-shield" />
                        <h4>Finalize</h4>
                        <p>Once you have found your dream plot, our experts will guide you through the necessary paperwork and legal procedures. We aim to make the buying process smooth and transparent for your peace of mind.</p>
                    </div>
                    <p>Investing in a residential plot in Telangana is a decision that promises future growth and prosperity. Start your journey with us today and let us help you find the perfect plot to build your dream home or make a wise investment.</p>

                </div>
            </section>




        </>
    )
}

export default Aboutuspage