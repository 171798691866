import React from 'react'
import { Link } from 'react-router-dom'
import { FcGlobe, FcVoicePresentation, FcOrganization, FcFilmReel } from "react-icons/fc";
import { FaLocationDot } from "react-icons/fa6";
import { MdPhotoSizeSelectSmall } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";
import { AiTwotoneHome } from "react-icons/ai";
import { FaMoneyBillAlt } from "react-icons/fa";

const OurResidentialVilles = () => {
    return (
        <>

            <section className="section-md bg-gray-lighter">
                <div className="container">
                    <div className="row justify-content-md-center flex-lg-row-reverse align-items-lg-center justify-content-lg-between row-50">
                        <div className="col-md-6 col-lg-6">
                            <h3 className="heading-decorated">Our Residential Plots</h3>
                            <p>Green Ville Projects Pvt. Ltd, we offer a wide selection of residential plots in Telangana that suits to different preferences and budgets. Our team of experts has carefully curated these plots, ensuring that they are well-located, legally authorized, and offer all the necessary amenities for a comfortable living experience enabling good returns in a short time.</p>
                            <div className="col-lg-12 mt-1" data-aos="zoom-in" data-aos-delay={100}>
                                <div className="icon-box">
                                    <div className='IcCon' >
                                        <div className='splIcons'><FaLocationDot /></div>
                                        <div><h4><a className='splHeadings'>Prime Locations </a></h4>
                                            <p>Our plots are mainly located in popular and upcoming residential areas with good connectivity to major cities, business hubs, and educational institutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-1" data-aos="zoom-in" data-aos-delay={200}>
                                <div className="icon-box">
                                    <div className='IcCon '>
                                        <div className='splIcons'><MdPhotoSizeSelectSmall /></div>
                                        <div>
                                            <h4><a>Sizes for Every Need</a></h4>
                                            <p>We offer a range of plot sizes to suit your specific requirements and budget. Whether you are looking for a compact plot or a more spacious one, we have something for everyone.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-1" data-aos="zoom-in" data-aos-delay={300}>
                                <div className="icon-box">
                                    <div className='IcCon'>
                                        <div className='splIcons'><GrCompliance /></div>
                                        <div>
                                            <h4><a href>Legal Compliance</a></h4>
                                            <p>We understand the importance of legal compliance when it comes to real estate investments. Rest assured, all our residential plots stick to the necessary legal requirements and have clear titles, and all necessary approvals from concerned government authorities.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-1" data-aos="zoom-in" data-aos-delay={100}>
                                <div className="icon-box">
                                    <div className='IcCon'>
                                        <div className='splIcons'><AiTwotoneHome /></div>
                                        <div>
                                            <h4><a href>Amenities</a></h4>
                                            <p>We prioritize your comfort and convenience. Our residential plots come with essential amenities such as well planted drainage, sewerage system, electricity, well-lit roads, and security measures.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-1" data-aos="zoom-in" data-aos-delay={100}>
                                <div className="icon-box">
                                    <div className='IcCon'>
                                        <div className='splIcons'><FaMoneyBillAlt /></div>
                                        <div>
                                            <h4><a href>Value for Money</a></h4>
                                            <p>We believe in offering quality plots at fair prices. Our plots provide excellent value for money, making them a excellent investment for the long term.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="property-app-image">
                                        <img src="assets/images/greenville10.webp" alt />
                                    </div>
                                    <div className="flicker-feed gutter">
                                        <Link to="" className="lightbox-image mt-1" title="Image Caption Here">
                                            <img src="assets/images/greenville6.webp" alt /></Link>
                                        <Link to="" className="lightbox-image mt-1" title="Image Caption Here">
                                            <img src="assets/images/greenville5.webp" alt /></Link>
                                        <Link to="" className="lightbox-image mt-1" title="Image Caption Here">
                                            <img src="assets/images/greenville11.webp" alt /></Link>
                                        <Link to="" className="lightbox-image mt-1" title="Image Caption Here">
                                            <img src="assets/images/greenville7.webp" alt /></Link>
                                        <Link to="" className="lightbox-image mt-1" title="Image Caption Here">
                                            <img src="assets/images/greenville9.webp" alt /></Link>
                                        <Link to="" className="lightbox-image mt-1" title="Image Caption Here">
                                            <img src="assets/images/greenville10.webp" alt /></Link>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <div className="container col-md-6 col-lg-6 d-flex" style={{ justifyContent: "center", alignItems: 'center' }}>
                            <img src="assets/images/property/1.jpg" alt width={900} height={700} />
                        </div> */}
                    </div>
                </div>
            </section>



        </>
    )
}

export default OurResidentialVilles