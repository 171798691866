import { BrowserRouter, HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import MainLayOut from './component/MainLayOut';
import Home1 from './pages/home/Home1';
import NewProjectSection from './pages/NewProjectSection';
import ServicesPage from './pages/ServicesPage';
import ImageGallary from './pages/ImageGallary';
import ContactUsPage from './pages/ContactUsPage';
import BlogPage from './pages/BlogPage';
import FaqPage from './pages/FaqPage';
import Aboutuspage from './pages/Aboutuspage';
import PropertyDetails from './pages/PropertyDetails';
import { useEffect } from 'react';
import Awards from './pages/Awards';
import Layouts from './pages/Layouts';

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }


  return (
    <>
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
            <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home1 />} />
            <Route path="/projects" element={<NewProjectSection />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/imageGallary" element={<ImageGallary />} />
            <Route path="/contactUs" element={<ContactUsPage />} />
            <Route path="/blog" element={<BlogPage/>}/>
            {/* <Route path="/faqPage" element={<FaqPage/>}/> */}
            <Route path="/aboutus" element={<Aboutuspage/>}/>
            <Route path="/projectdetails/lorven-country" element={<PropertyDetails/>}/>
            {/* <Route path="/awards" element={<Awards/>}/> */}
            <Route path="/layouts" element={<Layouts/>}/>
          
          </Route>

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
