import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [Open, setOpen] = useState(false);
    const closeMenu = () => { setOpen(false); };
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    return (
        <>
    <header className="main-herader">
                <div className="top_header">
                    <div className="container p-0">
                        <div className="row">
                            {/* <div className="col-md-12">
                        <div className="header-topbar-col center767">
                            <ul className="list-inline pull-left address center767">
                                <li><i className="fa fa-envelope" /><Link >info@greenvilleprojects.com</Link></li>
                                <li><i className="fa fa-phone" /><Link >+91 9392121249 </Link></li>
                                <li className="display-n-768"><i className="fa fa-map-marker" /><Link >H.NO 8-2-686/2/6/1/A, GROUND FLOOR, BANJARA HILLS ROAD, 12, 500034, Hyderabad,Telangana</Link></li>
                            </ul>
                            <ul className="socials-icon pull-right center767">
                                <li><Link ><i className="fa fa-facebook-f" /></Link></li>
                                <li><Link ><i className="fa fa-twitter" /></Link></li>
                                <li><Link ><i className="fa fa-google-plus" /></Link></li>
                            </ul>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-default bootsnav" id="navbar-main">
                    <div className="top-search">
                        <div className="container p-0">
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-search" /></span>
                                <input type="text" className="form-control" placeholder="Search" />
                                <span className="input-group-addon close-search"><i className="fa fa-bars" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="container p-0">
                        <div className="navbar-header">
                            <button type="button" id='mobileMenu' className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-menu" onClick={() => setOpen(true)}>
                                {Open ? <span className="fa fa-bars" /> : <span className="fa fa-bars" />}
                            </button>
                            <div>
                                <Link to="/"><a className="navbar-brand"><img src="/assets/images/logo/finallogo3.webp" style={{ width: "100px", height: "70px" }} className="logo" alt /></a> </Link>
                                {/* <p>GreenVilleProjects</p> */}
                            </div>
                        </div>
                        <div className={`header collapse  navbar-collapse ${Open ? 'navbar-collapse' : ''}`} id="navbar-menu" >
                            <ul className="nav navbar-nav navbar-right" id="menu" data-in="fadeInUp" data-out="fadeOutDown" >
                                <li className="dropdown active" onClick={() => closeMenu()}>
                                    <Link to="/" >Home</Link>
                                </li>
                                <li className="dropdown megamenu-fw" onClick={() => closeMenu()}>
                                    <Link to="/aboutus" className="">ABOUT US</Link>
                                </li>
                                <li className="dropdown" >
                                    <Link className="dropdown-toggle" data-toggle="dropdown">PROJECTS</Link>
                                    <ul className="dropdown-menu">
                                        {/* <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link to="/" >NEW PROJECTS</Link>
                                        </li>
                                        <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link to="/projectdetails/lorven-country" >RUNNING PROJECTS</Link>
                                        </li>
                                        <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link   >UPCOMING PROJECTS</Link>
                                        </li> */}
                                        <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link  to="/projects" >COMPLETED PROJECTS</Link>
                                        </li>
                                    </ul>
                                </li>
                                {/* <li className="dropdown megamenu-fw" onClick={() => closeMenu()}>
                                    <Link to="/services" className="
                                    ">SERVICES</Link>
                                </li> */}
                                {/* <li className="dropdown" onClick={() => closeMenu()}>
                            <Link to="/layouts" >Layouts</Link>
                        </li> */}
                                <li className="dropdown" onClick={() => closeMenu()}>
                                    <Link to="/blog" >Blog</Link>
                                </li>
                                <li className="dropdown" onClick={() => closeMenu()}>
                                    <Link to="/contactUs" >CONTACT US</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
    
        </>
    )
};


export default Header