import React from 'react'
import { Link } from 'react-router-dom';
import { SlLocationPin } from "react-icons/sl";
import { SlBadge } from "react-icons/sl";
import { SlGraph } from "react-icons/sl";
import { FaHandHoldingUsd } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
const BlogPage = () => {
  return (
    <>

      <div>
        <section className="banner-area over-layer-black">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h2>Blog</h2>
                  <p><Link to="/">Home</Link> / Blogs</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="blog-area">
          <div className="container">
            <div className="row">


              <div className="row bolgCon">
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>Investing in Hyderabad offers several important & interesting reasons. Firstly, Hyderabad is experiencing rapid economic growth, making it one of India's fastest-growing cities. This growth is expected to continue in the future, creating a favourable environment for investment opportunities.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>Secondly, Hyderabad boasts a high level of security, which is a major concern for homebuyers. As the city becomes a hub for premium and luxury residential projects, residents can expect excellent security measures that ensure stress-free living.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>Moreover, Hyderabad's economy is projected to continue growing steadily in the coming years, making it an attractive destination for property investment. The city's strong economic growth and increasing job opportunities contribute to a high demand for real estate</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>Additionally, Hyderabad offers excellent infrastructure, providing seamless connectivity to major sections of the city and throughout India. Its modern infrastructure supports convenient transportation and efficient connectivity, making it an attractive option for investors.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>Lastly, the Hyderabad Metropolitan Development Authority has extensive plans to transform the city's landscape, attracting major IT companies and retail franchises. The city's global recognition, coupled with job opportunities and modern infrastructure, make it an appealing location for investment.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>In summary, the robust economic growth, high-level security, steady economic development, excellent infrastructure, and future development plans make Hyderabad a lucrative destination for property investment.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>Looking for premium residential open plots with guaranteed increment every year and resale assurance. That sounds like a great investment opportunity!</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    <div className="icon-box">
                      {/* <i className="SlLocationPin" /> */}
                      <SlLocationPin/>                    
                    </div>
                    <h6><a  >Location :</a></h6>
                    <p>The location of the plot is crucial. Look for plots that are in high-demand areas, close to amenities like schools, supermarkets, hospitals, and transportation facilities. Also, consider factors like proximity to your workplace, tourist attractions, and future development plans in the area.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    <div className="icon-box">
                      {/* <i className="flaticon-key-2" /> */}
                      <SlBadge/>

                    </div>
                    <h6><a  >Developer Reputation :</a></h6>
                    <p> It's important to research the reputation of the developer before making a purchase. Look for developers who have a track record of delivering quality projects on time and with good resale value.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    <div className="icon-box">
                      {/* <i className="flaticon-key-2" /> */}
                        <SlGraph/>
                    </div>
                    <h6><a  >Increment Guarantee :</a></h6>
                    <p> Some developers offer guaranteed increment every year. Make sure to understand the terms and conditions related to this guarantee. Additionally, research the historical growth rate in property prices in the area to evaluate the feasibility of the guarantee.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    <div className="icon-box">
                      {/* <i className="flaticon-key-2" /> */}
                      <FaHandHoldingUsd />
                    </div>
                    <h6><a  >Resale Assurance :</a></h6>
                    <p> Resale assurance ensures that you will have an option to sell the plot at a certain minimum price in the future. Check the terms and conditions associated with the resale assurance, including the duration of the assurance, any fees involved, and whether there are any restrictions on the selling price.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    <div className="icon-box">
                      {/* <i className="flaticon-key-2" /> */}
                      <GoLaw/>
                    </div>
                    <h6><a  >Legal Due Diligence :</a></h6>
                    <p> Before making any investment, it's crucial to conduct proper legal due diligence. Ensure that all necessary approvals, permits, and documents are in place. Verify the title deed, survey maps, encumbrance certificate, and any other relevant documents to ensure a secure transaction.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="about-item style-1">
                    {/* <div className="icon-box">
                      <i className="flaticon-key-2" />
                    </div> */}
                    {/* <h6><a  >Our mission :</a></h6> */}
                    <p>Remember, it's always wise to consult with a real estate professional or a financial advisor to get expert advice tailored to your specific needs and goals. They can guide you through the process and help you make an informed decision.</p>
                  </div>
                </div>
              </div>


              <div className="col-md-12">
                <div className="row">
                  <div className="blog-box mb-4 d-flex">
                    <div className="col-12 col-md-12 col-lg-4">
                      <div className="image">
                        <img src="assets/images/greenville5.webp" alt="Image" />
                        <div className="over-layer">
                          <ul className="social-links">
                            <li><Link to="#" className="fa fa-twitter" /></li>
                            <li><Link to="#" className="fa fa-facebook" /></li>
                            <li><Link to="#" className="fa fa-instagram" /></li>
                          </ul>
                        </div>
                        {/* <div className="date-mate">
                          <h6>Aug<span>24</span>2018</h6>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                      <div className="blog-contant">
                        <h4><Link to="#">Global Economic growth </Link></h4>
                        {/* <div className="star-murk">
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star-outline" />
                          <span className="ion-ios-star-outline" />
                          <span className="review">( 12 Review )</span>
                        </div> */}
                        <p>Telangana is one of the fastest-growing states in India, with a strong focus on industry and technology. With a robust IT sector, pharmaceuticals, manufacturing hubs, textiles, and more, Telangana provides wide employment opportunities and a stable economic environment.</p>
                        {/* <div className="blog-comment-meta mb-3">
                          <ul>
                            <li>
                              <Link to="#"><i className="fa fa-heart-o" />212</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-comments-o" />132</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-eye" />111</Link>
                            </li>
                          </ul>
                        </div>
                        <button type="button" className="btn btn-theme text-uppercase">View Details</button> */}
                      </div>
                    </div>
                  </div>
                  <div className="blog-box mb-4 d-flex">
                    <div className="col-12 col-md-12 col-lg-4">
                      <div className="image">
                        <img src="assets/images/greenville6.webp" alt="Image" />
                        <div className="over-layer">
                          <ul className="social-links">
                            <li><Link to="#" className="fa fa-twitter" /></li>
                            <li><Link to="#" className="fa fa-facebook" /></li>
                            <li><Link to="#" className="fa fa-instagram" /></li>
                          </ul>
                        </div>
                        {/* <div className="date-mate">
                          <h6>Aug<span>24</span>2018</h6>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                      <div className="blog-contant">
                        <h4><Link to="#">Excellent Connectivity</Link></h4>
                        {/* <div className="star-murk">
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star-outline" />
                          <span className="ion-ios-star-outline" />
                          <span className="review">( 12 Review )</span>
                        </div> */}
                        <p>The state boasts well-connected networks of roads, railways, and airports, ensuring easy accessibility within Telangana as well as to other major cities in India. This makes traveling and commuting much easy.</p>
                        {/* <div className="blog-comment-meta mb-3">
                          <ul>
                            <li>
                              <Link to="#"><i className="fa fa-heart-o" />212</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-comments-o" />132</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-eye" />111</Link>
                            </li>
                          </ul>
                        </div>
                        <button type="button" className="btn btn-theme text-uppercase">View Details</button> */}
                      </div>
                    </div>
                  </div>
                  <div className="blog-box mb-4 d-flex">
                    <div className="col-12 col-md-12 col-lg-4">
                      <div className="image">
                        <img src="assets/images/greenville7.webp" alt="Image" />
                        <div className="over-layer">
                          <ul className="social-links">
                            <li><Link to="#" className="fa fa-twitter" /></li>
                            <li><Link to="#" className="fa fa-facebook" /></li>
                            <li><Link to="#" className="fa fa-instagram" /></li>
                          </ul>
                        </div>
                        {/* <div className="date-mate">
                          <h6>Aug<span>24</span>2018</h6>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                      <div className="blog-contant">
                        <h4><Link to="#">Infrastructure</Link></h4>
                        {/* <div className="star-murk">
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star-outline" />
                          <span className="ion-ios-star-outline" />
                          <span className="review">( 12 Review )</span>
                        </div> */}
                        <p>Telangana prides itself on its world-class infrastructure, including well-planned residential colonies, educational institutions, healthcare facilities, and recreational centers. You can be assured of a comfortable and convenient lifestyle.</p>
                        {/* <div className="blog-comment-meta mb-3">
                          <ul>
                            <li>
                              <Link to="#"><i className="fa fa-heart-o" />212</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-comments-o" />132</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-eye" />111</Link>
                            </li>
                          </ul>
                        </div>
                        <button type="button" className="btn btn-theme text-uppercase">View Details</button> */}
                      </div>
                    </div>
                  </div>
                  <div className="blog-box mb-4 d-flex">
                    <div className="col-12 col-md-12 col-lg-4">
                      <div className="image">
                        <img src="assets/images/greenville8.webp" alt="Image" />
                        <div className="over-layer">
                          <ul className="social-links">
                            <li><Link to="#" className="fa fa-twitter" /></li>
                            <li><Link to="#" className="fa fa-facebook" /></li>
                            <li><Link to="#" className="fa fa-instagram" /></li>
                          </ul>
                        </div>
                        {/* <div className="date-mate">
                          <h6>Aug<span>24</span>2018</h6>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                      <div className="blog-contant">
                        <h4><Link to="#">Cultural Heritage</Link></h4>
                        {/* <div className="star-murk">
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star" />
                          <span className="ion-ios-star-outline" />
                          <span className="ion-ios-star-outline" />
                          <span className="review">( 12 Review )</span>
                        </div> */}
                        <p>Telangana is known for its  in rich history and culture, with renowned landmarks and festivals that showcase the state's traditions. Living here means being a part of a vibrant community and experiencing the beauty of Telangana's cultural heritage.</p>
                        {/* <div className="blog-comment-meta mb-3">
                          <ul>
                            <li>
                              <Link to="#"><i className="fa fa-heart-o" />212</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-comments-o" />132</Link>
                            </li>
                            <li>
                              <Link to="#"><i className="fa fa-eye" />111</Link>
                            </li>
                          </ul>
                        </div>
                        <button type="button" className="btn btn-theme text-uppercase">View Details</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>



    </>
  )
}

export default BlogPage