import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';


import "swiper/css";
import "swiper/css/free-mode";

import { Autoplay, FreeMode, Pagination } from "swiper/modules";

const TestimonalSlider = () => {
    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={60}
            freeMode={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper"
            breakpoints={{
                360: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                470: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                580: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                1080: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
            }}
        >
            <div className="col">
            <div id="testimonial-carousel-slider" className="testimonial-carousel owl-carousel  owl-theme">
                    <SwiperSlide>
                        <div className="item">
                            
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
                                    <div className="single-testimonal">
                                        <div className="testimonal-image">
                                        <img className="rounded-circle" src="assets/images/testimonal/profile.webp" alt="Testimonal Image" />
                                        </div>
                                        <div className="testimonal-slider-content">
                                        {/* <p> Thank you so much for all you do! You've turned a stressful situation into something more manageable, being responsive and on top of everything, and just overall knowledgeable and truly helping me find a home I'd love.
                                        </p> */}
                                        <p>  <b>Suresh Reddy -</b> "I had a great experience . They were prompt and helped us find a great property. Would highly recommend them"</p>
                                        {/* <h5>Siva Kumar</h5> */}
                                        <i className="fa fa-quote-right" />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
                                        <div className="single-testimonal">
                                            <div className="testimonal-image">
                                            <img className="rounded-circle" src="assets/images/testimonal/profile.webp" alt="Testimonal Image" />
                                            </div>
                                            <div className="testimonal-slider-content">
                                            {/* <p> I am pleased and blessed with my experience with Arielle Rios. She was the most professional and kindest REALTOR. Arielle went above and beyond and I am so thankful for everything she has done for me.
                                            </p>
                                            <h5> Shubham Kumar</h5> */}
                                            <p><b>Shiv Kumar -</b> "I strongly recommend GreenVille Projects Pvt Ltd for their loyalty, trust and service"</p>
                                            <i className="fa fa-quote-right" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="item">
                        
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
                                <div className="single-testimonal">
                                    <div className="testimonal-image">
                                    <img className="rounded-circle" src="assets/images/testimonal/profile.webp" alt="Testimonal Image" />
                                    </div>
                                    <div className="testimonal-slider-content">
                                    {/* <p> She was always very responsive and professional. We will refer Sri Sai Balaji Estates to all of our friends and family in the market. She made our first home buying experience phenomenal!.
                                    </p>
                                    <h5>Suresh Reddy</h5> */}
                                    <p> <b>Shubham Kumar -</b> "They are providing best residential plots at best prices. GreenVille Projects is completely providing the oppurtunity to maximise return on investment for the customers"</p>
                                    <i className="fa fa-quote-right" />
                                    </div>
                                </div>
                                </div>
                            </div>
                        
                    </div>
                    </SwiperSlide>
                </div>
            </div>


        </Swiper>

    );
};

export default TestimonalSlider;