import React from 'react'
import { Link } from 'react-router-dom'

const PropertyDetails = () => {
    return (
        <>

            <section class="banner-area over-layer-black">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="page-title-box">
                                <h2>LORVEN COUNTY</h2>
                                <p><Link to="/"><a>Home</a></Link> / Project Details </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="property-single-pages bg-f8">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-8">
                            <div className="property-box">
                                <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li data-target="#carousel-example-generic" data-slide-to={0} className="active">
                                            <img src=" /assets/images/greenville5.jpg" alt="Property Image" />
                                        </li>
                                        <li data-target="#carousel-example-generic" data-slide-to={1}>
                                            <img src=" /assets/images/greenville6.jpg" alt="Property Image" />
                                        </li>
                                        <li data-target="#carousel-example-generic" data-slide-to={2}>
                                            <img src=" /assets/images/greenville10.jpg" alt="Property Image" />
                                        </li>
                                        <li data-target="#carousel-example-generic" data-slide-to={3}>
                                            <img src=" /assets/images/greenville7.jpg" alt="Property Image" />
                                        </li>
                                        <li data-target="#carousel-example-generic" data-slide-to={4}>
                                            <img src=" /assets/images/greenville9.jpg" alt="Property Image" />
                                        </li>
                                    </ol>
                                    <div className="carousel-inner style-2" role="listbox">
                                        <div className="item active">
                                            <img src=" /assets/images/greenville5.jpg" alt />
                                        </div>
                                        <div className="item">
                                            <img src=" /assets/images/greenville6.jpg" alt />
                                        </div>
                                        <div className="item">
                                            <img src=" /assets/images/greenville10.jpg" alt />
                                        </div>
                                        <div className="item">
                                            <img src=" /assets/images/greenville7.jpg" alt />
                                        </div>
                                        <div className="item">
                                            <img src=" /assets/images/greenville9.jpg" alt />
                                        </div>
                                    </div>
                                    <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                                        <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                                        <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" />
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                                <div className="property-content">
                                    <h4><a >Description</a></h4>
                                    {/* <p>LORVEN COUNTY located on Mumbai Highway @ Arur We are very glad to inform you that we successfully completed the project and started its extensions on customers demandhat we are sold 80% plots in our own prestigious project.</p> */}
                                    <p>LORVEN COUNTY by Green Ville Projects Pvt. Ltd. is located at the very prime location Sadasivpet, Arur village.It offers premium residential plots available in various sizes with the safety and security of a carefully planned gated community with wide open spaces and a friendly neighborhood.</p>
                                    <div className="condation">
                                        <h4><u>Condation</u></h4>
                                        <div className="condation-box">
                                            <ul>
                                                <li><i className="cl-sen flaticon-map" />Area 165 Sqyards</li>
                                                {/* <li><i className="cl-pry flaticon-bath-3" />Bathroom 3</li> */}
                                                {/* <li><i className="cl-sen flaticon-bottle" />Kitchen 2</li> */}
                                                {/* <li><i className="cl-pry flaticon-bed-2" />Bedroom 5</li> */}
                                                {/* <li><i className="cl-sen flaticon-buildings-3" />Garage 2</li> */}
                                                {/* <li><i className="cl-pry- flaticon-coin" />₹52,350</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="condation">
                                        <h5>Amenities</h5>
                                        <div className="condation-box">
                                            <ul>
                                                <li className='AmititiesContainer active'>
                                                    <div className='iconimgcon'>
                                                        <img src="\assets\images\icons\ic\gradn 100ft road entrance.png" alt className={'iconimage'} style={{ color: 'green' }} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>Banquet hall</p> */}
                                                        <p className='iconText'>Gated Community </p>
                                                    </div>
                                                </li>
                                                <li className='AmititiesContainer '>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\images.jpg" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>Yoga/Meditation center</p> */}
                                                        <p className='iconText'>24 Hrs Security </p>
                                                    </div>
                                                </li>
                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\furnished club house.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>Party lawn</p> */}
                                                        <p className='iconText'>Club House </p>
                                                    </div>
                                                </li>
                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\100ft road.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>100 Ft.paver road</p> */}
                                                        <p className='iconText'>Gymnasium</p>
                                                    </div>
                                                </li>

                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\UG sump and OHT .png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>UG sump and OHT</p> */}
                                                        <p className='iconText'>Kids Play Area</p>
                                                    </div>
                                                </li>


                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\Alluring landscapes.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>Alluring landscapes</p> */}
                                                        <p className='iconText'>Jogging Track</p>
                                                    </div>
                                                </li>


                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\Jogging track.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>Jogging track</p> */}
                                                        <p className='iconText'>Volleyball Court</p>
                                                    </div>
                                                </li>


                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\paver. entrance  plots.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>Paver entrance for plots</p> */}
                                                        <p className='iconText'>Badminton Court</p>
                                                    </div>
                                                </li>


                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\Under ground drainage system.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        {/* <p className='iconText'>Under ground drainage system</p> */}
                                                        <p className='iconText'>Recreational Park</p>
                                                    </div>
                                                </li>

                                                {/* <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\furnished club house.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        <p className='iconText1'>Furnished Club house</p>
                                                    </div>
                                                </li>

                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\Compound wall on all side.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        <p className='iconText1'>Compound wall on all side</p>
                                                    </div>
                                                </li>

                                                <li className='AmititiesContainer'>
                                                    <div>
                                                        <img src="\assets\images\icons\ic\gradn 100ft road entrance.png" alt className={"iconimage"} />
                                                    </div>
                                                    <div>
                                                        <p className='iconText1'>Gradn 100ft road entrance</p>
                                                    </div>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="blog-sideber pt-3 pb-3">
                                {/* <div className="widget clearfix">
                                    <div className="blog-search">
                                        <form  className="clearfix">
                                            <input type="search" placeholder="Search Here.." />
                                            <button type="submit">
                                                <span className="pe-7s-search" />
                                            </button>
                                        </form>
                                    </div>
                                </div> */}
                                <div className="widget clearfix">
                                    <div className="sideber-title">
                                        <h4>Recent Propertys</h4>
                                    </div>
                                    <div className="widget-post">
                                        <div className="news-area clearfix">
                                            <div className="news-img">
                                                <a >
                                                    <img src="/assets/images/greenville33.png" alt />
                                                    <span className="fa fa-link" />
                                                </a>
                                            </div>

                                        </div>
                                        <div className="news-area clearfix">
                                            <div className="news-img">
                                                <a >
                                                    <img src="/assets/images/greenville34.jpg" alt />
                                                    <span className="fa fa-link" />
                                                </a>
                                            </div>

                                        </div>
                                        <div className="news-area clearfix">
                                            <div className="news-img">
                                                <a >
                                                    <img src="/assets/images/greenville35.webp" alt />
                                                    <span className="fa fa-link" />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="widget clearfix">
                                    <div className="sideber-title">
                                        <h4>Fliker Feed</h4>
                                    </div>
                                    <div className="flicker-feed gutter">
                                        <a className="lightbox-image" ><img src="/assets/images/greenville5.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" ><img src="/assets/images/greenville6.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" ><img src="/assets/images/greenville7.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" ><img src="/assets/images/greenville8.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" > <img src="/assets/images/greenville9.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" > <img src="/assets/images/greenville10.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" > <img src="/assets/images/greenville11.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" > <img src="/assets/images/greenville12.jpg" alt className='img1' /> </a>
                                        <a className="lightbox-image" ><img src="/assets/images/greenville13.jpg" alt className='img1' /> </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="portfolio-area " 
            // style={{marginTop:'-10%'}}
            >
                <div className="container">
                    <h3 style={{ display: 'flex', justifyContent: 'center' }}><u >Layout    </u></h3>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="portfolio-content mt-3" >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ width: '750px', textAlign: 'center' }}><p >We are engaged in development, property acquisition and sale of real estate properties. To provide affordable residential plots to clients for them to build their homes which are affordable and easily accessible has been our prime goal and urgent concern.</p></div>
                                </div>
                                <div className="row mt-3 " >
                                    <div className="col-sm-12 col-md-12" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="col-sm-10 col-md-10">
                                            <div className="hover-box">
                                                <img src="\assets\images\layout1.jpg" alt />
                                                <div className="hover-layer" style={{ opacity: 0.3 }} />
                                                <div className="hover-box-content">
                                                    <a ><i className="fa fa-arrows" aria-hidden="true" /></a>
                                                    <a ><i className="fa fa-link" aria-hidden="true" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12" >
                                            <div class=" container download-brocher bounceIn animated abc2 col-sm-11 col-md-11" style={{ paddingTop: '28px' }} >
                                                <a href='\assets\images\Lorven County - Phase 1,2,3 - UpdatedD.pdf' target='_blank' download id='abc'>Download Brochure</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="location-area">
                                    <h3>Land Features</h3>
                                    <li>Clear title plot </li>
                                    <li>Boundary compound wall surrounding project</li>
                                    <li>Center adjacent to main highway road NH-65</li>
                                    <li>Water connection </li>
                                    <li>Electricity connection</li>
                                    <li>Close to schools and university</li>
                                    {/* <h6>Location HighLights</h6>
                                    <p>Near to RRR</p>
                                    <p>Near to ORR</p>
                                    <p>Near to MRF</p>
                                    <p>Near to WOXSEN UNIVERSITY</p>
                                    <p>NEAR TO NIMZ PROJECT</p>
                                    <p>NEAR TO RAILWAY STATION (ZAHEERABAD)</p>
                                    <p>15 MINS DRIVE FROM SANGAREDDY</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>








        </>
    )
}

export default PropertyDetails