import React from 'react'
import HomeBanner from '../HomeBanner'
import FindProperty from './FindProperty'
import WhatyouLookingFor from './WhatyouLookingFor'
import FloorPlans from './FloorPlans'
import VideoParalex from './VideoParalex'
import OurProperties from './OurProperties'
import CounterSection from './CounterSection'
import Testimonal from './Testimonal'
import SalogenText from './SalogenText'
import OurLatestBlog from './OurLatestBlog'
import LocationPart from './LocationPart'
import HowToGetStart from './HowToGetStart'
import WhyYouChoose from './WhyYouChoose'
import OurResidentialVilles from './OurResidentialVilles'
import SplQuotes from './SplQuotes'


function Home1() {
    return (
        <>
            <HomeBanner />
            {/* <FindProperty /> */}
            {/* <WhatyouLookingFor /> */}
            {/* <WhyYouChoose/> */}
            {/* <HowToGetStart/> */}
            {/* <FloorPlans /> */}
            <OurResidentialVilles/>
            <Testimonal /> 
            {/* <SplQuotes/> */}
            {/* <VideoParalex /> */}
            {/* <CounterSection /> */}
            {/* <OurProperties /> */}
            {/* <SalogenText/>  */}
            {/* <OurLatestBlog/> */}
            {/* <LocationPart/> */}
        </>
    )
}

export default Home1