import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <>
      <footer className="bg-faded footer-bg-image">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-3">
                <div className="footer-item footer-widget-one">
                  <Link to="/">  <img className="footer-logo" src="/assets/images/logo/finallogo3.webp" alt="GREEN VILLE" /></Link>
                  {/* <p> GREEN VILLE</p> */}
                  {/* <Link to="/"><a className="navbar-brand"><img src="assets/images/logo/finallogo3.jpg" style={{width:"100px",height:"50px"}} className="logo" alt="GREEN VILLE" /></a> </Link> */}
                  <address>
                    <p>Corporate Office:-  8-2-686/2/6/1/A, GROUND FLOOR,
                      BANJARA HILLS ROAD, 12, 500034, Hyderabad,Telangana</p>
                    <ul className="address">
                      <li><i className="pe-7s-call" />Phone:+91 4029391369 </li>
                      <li><i className="pe-7s-mail" /><a to="mailtoinfo@greenvilleprojects.com:">Email: info@greenvilleprojects.com</a></li>
                    </ul>
                  </address>
                  <ul className="social-icon bg-theme">
                    <li><i className="fa fa-twitter" aria-hidden="true" style={{ color: 'white' }} /></li>
                    {/* <li><i className="fa fa" aria-hidden="true" /></li> */}
                    <li><i className="fa fa-instagram" aria-hidden="true" style={{ color: 'white' }} /></li>
                    {/* <li><i className="fa fa" aria-hidden="true" /></li> */}
                    <li><i className="fa fa-facebook-f" aria-hidden="true" style={{ color: 'white' }} /></li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="footer-item footer-widget-two">
            <div className="footer-title">
              <h4>Recent Post</h4>
              <div className="border-style-1" />
            </div>
            <div className="news-area clearfix">
              <div className="news-img">
                <Link to="">
                  <img src="/assets/images/greenville25.png" alt />
                  <span className="fa fa-link" />
                </Link>
              </div>
              <div className="news-content">
                <p><Link to="">LORVEN COUNTY</Link></p>
                <span>AROOR- NH - 65 (MUMBAI HIGHWAY)</span>
              </div>
            </div>

            
          </div>
        </div> */}
              {/* <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="footer-item">
            <div className="footer-title">
              <h4>Help Us </h4>
              <div className="border-style-1" />
            </div>
            <ul className="footer-list">
              <li><i className="pe-7s-angle-right" /><Link to="/aboutus">About Us</Link></li>
              <li><i className="pe-7s-angle-right" /><Link to="/services">Services</Link></li>
              <li><i className="pe-7s-angle-right" /><Link to="/contactUs">Help and support</Link></li>
              <li><i className="pe-7s-angle-right" /><Link to="/blog">Read our blog</Link></li>
            </ul>
          </div>
        </div> */}
              <div className="col-xs-12 col-sm-6 col-md-9">
                <div className="footer-item">
                  <div className="footer-title">
                    <h4>Quick Contact</h4>
                    <div className="border-style-1" />
                  </div>
                  <form method="post" action="#">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input type="email" name="Name" className="form-control" placeholder="Enter Name" required />
                      </div>
                      <div className="form-group col-md-12">
                        <input type="email" name="email" className="form-control" placeholder="Enter Email" required />
                      </div>
                      <div className="form-group col-md-12">
                        <div className="contact-textarea">
                          <textarea className="form-control" rows={4} placeholder="Enter message" name="    message" required defaultValue={""} />
                          <button type="button" className="btn btn-theme text-uppercase">Send
                            Message</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section className="footer-copy-right bg-theme-color text-white">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p>© 2024 Green Ville Projects Pvt. Ltd Designed By  <a href='https://dexterous.in/' target='_blank' style={{color:"white"}}>Dexterous Technology</a></p>
            </div>
          </div>
        </div>
      </section>



    </>
  )
}

export default Footer;