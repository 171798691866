import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';


import 'swiper/css';

import { Autoplay } from 'swiper/modules';

export default function HomeBannerSlider() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 3200,
                    disableOnInteraction: false,
                }}


                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="item active slide-1">
                        <div className="carousel-caption">
                            <h3 data-animation="animated fadeInUp"> Welcome To  <span>Green Ville</span>.</h3>
                            <p data-animation="animated fadeInDown">Finding your ideal residential plot in Telangana is just a few steps away
                            </p>
                            <Link to="/aboutus" className="btn btn-default" data-animation="animated fadeInLeft">Read more</Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item slide-2">
                        <div className="carousel-caption">
                            <h3 data-animation="animated fadeInUp">
                                Make good Green Ville <span>property</span>.
                            </h3>
                            <p data-animation="animated fadeInDown">
                                Finding your ideal residential plot in Telangana is just a few steps away
                            </p>
                            <div className='abc3'><Link to="/aboutus" className="btn btn-default" data-animation="animated fadeInLeft">Read more</Link></div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item slide-3">
                        <div className="carousel-caption">
                            <h3 data-animation="animated fadeInUp">
                            Find Your Dream  <span> House By Us</span> .
                            </h3>
                            <p data-animation="animated fadeInDown">
                                Finding your ideal residential plot in Telangana is just a few steps away
                            </p>
                            <Link to="/aboutus" className="btn btn-default" data-animation="animated fadeInLeft">Read more</Link>
                        </div>
                    </div>
                </SwiperSlide>



            </Swiper>
        </>
    );
}
