import React from 'react'
import { Link } from 'react-router-dom';

const NewProjectSection = () => {
  return (
    <>
    
<div>
  <section className="banner-area over-layer-black">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <h2>Projects</h2>
            <p><Link to="/">Home</Link> / PROJECTS</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="property-area">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="property-grid-item">
            <div className="property-box">
              <div className="image">
                <Link to='/propertydetails'><img src="assets/images/greenville5.webp" alt="Property_Image" /></Link>
                
                <div className="over-layer">
                  <ul className="social-links">
                    <li><Link to="/projectdetails/lorven-country" className="fa fa-link" /></li>
                    {/* <li><Link to="" className="fa fa-search" /></li> */}
                  </ul>
                </div>
                <div className="property-info">
                <p style={{color:'white'}}><i className="flaticon-drawing" style={{color:'green'}} />165 sq yards</p>
                  {/* <ul>
                    <li><i className="flat flaticon-drawing" /> 285 m2</li>
                    <li><i className="flat flaticon-bed-6" /> 4 bed</li>
                    <li><i className="flat flaticon-toilet" /> 2 bath</li>
                  </ul> */}
                </div>
              </div>
              <div className="sale"><span>For Sale</span></div>
              <div className="property-content">
                <h4><Link to="/projectdetails/lorven-country">LORVEN COUNTY</Link></h4>
                <span><i className="ion-ios-location-outline" />AROOR- NH - 65 (MUMBAI HIGHWAY)</span>
                <p>LORVEN COUNTY located on Mumbai Highway @ Arur We are very glad to inform you that we successfully completed the project and started its extensions on customers demandhat we are sold 80% plots in our own prestigious project.</p>
                <div className="property-footer">
                  {/* <div className="pricing"><span>₹ 1,000,005</span></div> */}
                  <ul className="social-icon">
                    {/* <li><Link to="/projectdetails/lorven-country"><i className="ion-android-share-alt" /></Link></li> */}
                    {/* <li><Link to="#"><i className="ion-ios-star-outline" /></Link></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="property-grid-item">
            <div className="property-box">
              <div className="image">
                <Link to='/propertydetails'><img src="assets/images/greenville6.jpg" alt="Property_Image" /></Link>
                
                <div className="over-layer">
                  <ul className="social-links">
                    <li><Link to="" className="fa fa-link" /></li>
                    <li><Link to="" className="fa fa-search" /></li>
                  </ul>
                </div>
                <div className="property-info">
                  <ul>
                    <li><i className="flat flaticon-drawing" /> 285 m2</li>
                    <li><i className="flat flaticon-bed-6" /> 4 bed</li>
                    <li><i className="flat flaticon-toilet" /> 2 bath</li>
                  </ul>
                </div>
              </div>
              <div className="sale"><span>For Sale</span></div>
              <div className="property-content">
                <h4><Link to="/propertydetails">LORVEN COUNTY</Link></h4>
                <span><i className="ion-ios-location-outline" />AROOR- NH - 65 (MUMBAI HIGHWAY)</span>
                <p>LORVEN COUNTY located on Mumbai Highway @ Arur We are very glad to inform you that we successfully completed the project and started its extensions on customers demandhat we are sold 80% plots in our own prestigious project.</p>
                <div className="property-footer">
                  <div className="pricing"><span>₹ 1,000,005</span></div>
                  <ul className="social-icon">
                    <li><Link to="#"><i className="ion-android-share-alt" /></Link></li>
                    <li><Link to="#"><i className="ion-ios-star-outline" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="property-grid-item">
            <div className="property-box">
              <div className="image">
                <Link to='/propertydetails'><img src="assets/images/greenville7.jpg" alt="Property_Image" /></Link>
                
                <div className="over-layer">
                  <ul className="social-links">
                    <li><Link to="" className="fa fa-link" /></li>
                    <li><Link to="" className="fa fa-search" /></li>
                  </ul>
                </div>
                <div className="property-info">
                  <ul>
                    <li><i className="flat flaticon-drawing" /> 285 m2</li>
                    <li><i className="flat flaticon-bed-6" /> 4 bed</li>
                    <li><i className="flat flaticon-toilet" /> 2 bath</li>
                  </ul>
                </div>
              </div>
              <div className="sale"><span>For Sale</span></div>
              <div className="property-content">
                <h4><Link to="/propertydetails">LORVEN COUNTY</Link></h4>
                <span><i className="ion-ios-location-outline" />AROOR- NH - 65 (MUMBAI HIGHWAY)</span>
                <p>LORVEN COUNTY located on Mumbai Highway @ Arur We are very glad to inform you that we successfully completed the project and started its extensions on customers demandhat we are sold 80% plots in our own prestigious project.</p>
                <div className="property-footer">
                  <div className="pricing"><span>₹ 1,000,005</span></div>
                  <ul className="social-icon">
                    <li><Link to="#"><i className="ion-android-share-alt" /></Link></li>
                    <li><Link to="#"><i className="ion-ios-star-outline" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        
        {/* <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="property-grid-item">
            <div className="property-box">
              <div className="image">
                <img src="assets/images/property/4.jpg" alt="Property_Image" />
                <div className="over-layer">
                  <ul className="social-links">
                    <li><Link to="#" className="fa fa-link" /></li>
                    <li><Link to="#" className="fa fa-search" /></li>
                  </ul>
                </div>
                <div className="property-info">
                  <ul>
                    <li><i className="flat flaticon-drawing" /> 285 m2</li>
                    <li><i className="flat flaticon-bed-6" /> 4 bed</li>
                    <li><i className="flat flaticon-toilet" /> 2 bath</li>
                  </ul>
                </div>
              </div>
              <div className="sale"><span>For Sale</span></div>
              <div className="property-content">
                <h4><Link to="Property_details_one.html">Nort Forest Family House</Link></h4>
                <span><i className="ion-ios-location-outline" />105,Paramus,NY,USA</span>
                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit.</p>
                <div className="property-footer">
                  <div className="pricing"><span>₹ 1,000,005</span></div>
                  <ul className="social-icon">
                    <li><Link to="#"><i className="ion-android-share-alt" /></Link></li>
                    <li><Link to="#"><i className="ion-ios-star-outline" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="property-grid-item">
            <div className="property-box">
              <div className="image">
                <img src="assets/images/property/5.jpg" alt="Property_Image" />
                <div className="over-layer">
                  <ul className="social-links">
                    <li><Link to="#" className="fa fa-link" /></li>
                    <li><Link to="#" className="fa fa-search" /></li>
                  </ul>
                </div>
                <div className="property-info">
                  <ul>
                    <li><i className="flat flaticon-drawing" /> 285 m2</li>
                    <li><i className="flat flaticon-bed-6" /> 4 bed</li>
                    <li><i className="flat flaticon-toilet" /> 2 bath</li>
                  </ul>
                </div>
              </div>
              <div className="sale"><span>For Sale</span></div>
              <div className="property-content">
                <h4><Link to="Property_details_one.html">Nort Forest Family House</Link></h4>
                <span><i className="ion-ios-location-outline" />105,Paramus,NY,USA</span>
                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit.</p>
                <div className="property-footer">
                  <div className="pricing"><span>₹ 1,000,005</span></div>
                  <ul className="social-icon">
                    <li><Link to="#"><i className="ion-android-share-alt" /></Link></li>
                    <li><Link to="#"><i className="ion-ios-star-outline" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="property-grid-item">
            <div className="property-box">
              <div className="image">
                <img src="assets/images/property/6.jpg" alt="Property_Image" />
                <div className="over-layer">
                  <ul className="social-links">
                    <li><Link to="#" className="fa fa-link" /></li>
                    <li><Link to="#" className="fa fa-search" /></li>
                  </ul>
                </div>
                <div className="property-info">
                  <ul>
                    <li><i className="flat flaticon-drawing" /> 285 m2</li>
                    <li><i className="flat flaticon-bed-6" /> 4 bed</li>
                    <li><i className="flat flaticon-toilet" /> 2 bath</li>
                  </ul>
                </div>
              </div>
              <div className="sale"><span>For Sale</span></div>
              <div className="property-content">
                <h4><Link to="Property_details_one.html">Nort Forest Family House</Link></h4>
                <span><i className="ion-ios-location-outline" />105,Paramus,NY,USA</span>
                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit.</p>
                <div className="property-footer">
                  <div className="pricing"><span>₹ 1,000,005</span></div>
                  <ul className="social-icon">
                    <li><Link to="#"><i className="ion-android-share-alt" /></Link></li>
                    <li><Link to="#"><i className="ion-ios-star-outline" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </section>
</div>

    
    
    
    </>
  )
}

export default NewProjectSection