import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const ContactUsPage = () => {
  const [name, setName] = useState("")
  const [name2, setName2] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [formData, setFormData] = useState({});
  // console.log(name);

  
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = {
      name: name,
      name2: name2,
      email: email,
      subject: subject,
      message: message
    }
    setFormData(formdata)
    const recipientEmail = 'info@greenvilleprojects.com';
    const emailSubject = encodeURIComponent(subject);
    const emailBody = encodeURIComponent(message);
    const mailtoLink = `mailto:${recipientEmail}?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = mailtoLink;

    setName("")
    setName2("")
    setEmail("")
    setSubject("")
    setMessage("")
  };


  return (
    <>

      <div>
        <section className="banner-area over-layer-black">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h2>Contact</h2>
                  <p><Link to="/">Home</Link> / Contact Us</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-area" >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="service-item style-1 border-1px">
                    <div className="service-icon">
                      <i className="pe-7s-map" />
                    </div>
                    <div className="content">
                      <h5><Link to="#">Contact Info</Link></h5>
                      <p>Corporate Office:- 8-2-686/2/6/1/A, GROUND FLOOR, BANJARA HILLS ROAD, 12,500034, Hyderabad,Telangana,</p>
                      <p>ph no:- <b> +91 4029391369 </b></p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="service-item style-1 border-1px">
                    <div className>
                      <i className="pe-7s-clock" />
                    </div>
                    <div className="content">
                      <h5><Link to="#">Business Hours</Link></h5>
                      <p>Monday-Friday: 10am to 8pm <br />Saturday: 11am to 3pm</p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="service-item style-1 border-1px">
                    <div className>
                      <i className="pe-7s-mail-open" />
                    </div>
                    <div className="content">
                      <h5><Link to="#">Email</Link></h5>
                      <p><Link to="#">info@greenvilleprojects.com</Link> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container" 
          // style={{marginTop:'-5%'}}
          >
            <div className="section-content">
              <div className="row d-flex">
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div className="section-title mb-5">
                    <h3>Get in Touch</h3>
                    <div className="border-style-1" />
                  </div>
                  <form id="ajax-contact" method="post" onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" name="name" id="name" className="form-control" placeholder="First Name" value={name} onChange={(e)=>setName(e.target.value)} required />
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" name="name2" id="name2" className="form-control" placeholder="Last Name" value={name2} onChange={(e)=>setName2(e.target.value)}/>
                      </div>
                      <div className="form-group col-md-12">
                        <input type="email" name="email" id="email" className="form-control" placeholder="Your Email" required value={email} onChange={(e)=>setEmail(e.target.value)}/>
                      </div>
                      {/* <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="form-control" placeholder="First Name" required /> */}
                      <div className="form-group col-md-12">
                        <div className="contact-textarea">
                          <textarea className="form-control" rows={6} placeholder="Write Message" id="message" name="message" required  value={message} onChange={(e)=>setMessage(e.target.value)}/>
                          <button className="btn btn-theme mt-4" type="submit" value="Submit Form">Send Message</button>
                        </div>
                      </div>
                      <div id="form-messages" />
                    </div>
                  </form>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div id="map" className="theme-color" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0, }}>
                      <div className="gm-err-container">
                        <div className="gm-err-content">
                        <br/>
                        <br/>
                        <br/>
                          <div className="gm-err-icon">
                            <img src="assets/images/contact-banner_o.webp" alt="" draggable="false" style={{ userSelect: 'none' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>





      </div>


    </>
  )
}

export default ContactUsPage