import React from 'react'

const Layouts = () => {
    return (
        <>
            <section className="portfolio-area">
                <div className="container">
                    <h3 style={{ display: 'flex', justifyContent: 'center' }}><u >LayOuts</u></h3>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="portfolio-content mt-3" >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ width: '750px', textAlign: 'center' }}><p >We are engaged in development, property acquisition and sale of real estate properties. To provide affordable residential plots to clients for them to build their homes which are affordable and easily accessible has been our prime goal and urgent concern.</p></div>

                                </div>
                                <div className="row mt-3 " >
                                    <div className="col-sm-12 col-md-12" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="col-sm-10 col-md-10">
                                            <div className="hover-box">
                                                <img src="assets\images\layout1.jpg" alt />
                                                <div className="hover-layer" style={{ opacity: 0.3 }} />
                                                <div className="hover-box-content">
                                                    <a ><i className="fa fa-arrows" aria-hidden="true" /></a>
                                                    <a ><i className="fa fa-link" aria-hidden="true" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12" >
                                        <div class=" container download-brocher bounceIn animated abc2 col-sm-11 col-md-11" style={{paddingTop:'28px'}} >
                                            <a href='assets\images\Lorven County - Phase 1,2,3 - UpdatedD.pdf' target='_blank' download id='abc'>Download Brochure</a>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </>
    )
}

export default Layouts