import React from 'react'
import { Link } from 'react-router-dom';
import CounterSection from './home/CounterSection';
import AboutUsSlider from '../component/slider/AboutUsSlider';

const ServicesPage = () => {
  return (
    <>

      <section className="banner-area over-layer-black">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h2>Services</h2>
                <p><a >Home</a> / Services</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="service-item style-2">
                <div className="icon">
                  <i className="flaticon-construction" />
                </div>
                <h6><a >Find properties</a></h6>
                <p>Real estate is about relationships, reputation and trust. You want to buy or sell with full
                  confidence.</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="service-item style-2">
                <div className="icon">
                  <i className="flaticon-map-2" />
                </div>
                <h6><a >List properties</a></h6>
                <p>Real estate is about relationships, reputation and trust. You want to buy or sell with full
                  confidence.</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="service-item style-2">
                <div className="icon">
                  <i className="flaticon-house-3" />
                </div>
                <h6><a >Sell properties</a></h6>
                <p>Real estate is about relationships, reputation and trust. You want to buy or sell with full
                  confidence.</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="service-item style-2">
                <div className="icon">
                  <i className="flaticon-money-1" />
                </div>
                <h6><a >Buy properties</a></h6>
                <p>Real estate is about relationships, reputation and trust. You want to buy or sell with full
                  confidence.</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="service-item style-2">
                <div className="icon">
                  <i className="flaticon-wrench-1" />
                </div>
                <h6><a >Fabuloso View</a></h6>
                <p>Real estate is about relationships, reputation and trust. You want to buy or sell with full
                  confidence.</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="service-item style-2">
                <div className="icon">
                  <i className="flaticon-swimming-pool" />
                </div>
                <h6><a >Swimming Pools</a></h6>
                <p>Real estate is about relationships, reputation and trust. You want to buy or sell with full
                  confidence.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="funfact-area parallax-bg over-layer-black" data-background="images/bg/2.jpg" data-type="parallax" data-speed={3}>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-col text-center">
                <div className="count">
                  <i className="flaticon-people-4" />
                  <div className="start-count">12345</div>
                </div>
                <p>Agents</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-col text-center">
                <div className="count">
                  <i className="flaticon-house-3" />
                  <div className="start-count">23456</div>
                </div>
                <p>Property</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-col text-center">
                <div className="count">
                  <i className="flaticon-map-2" />
                  <div className="start-count">34567</div>
                </div>
                <p>Project</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-col text-center">
                <div className="count">
                  <i className="flaticon-house-2" />
                  <div className="start-count border-none">45678</div>
                </div>
                <p>Houses</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="team-area bg-f8">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
              <div className="sec-title text-center mb-5">
                <h2>Estate Agents</h2>
                <p>Deserunt quia ducimus ut illum optio cum eum voluptate <br /> corrupti numquam.</p>
              </div>
            </div>
          </div>
          
            <AboutUsSlider/>
          </div>
      </section> */}




    </>
  )
}

export default ServicesPage